<template>
  <div id="clients-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
          :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <client-form v-if="clientId" />
    <client-home v-else />
  </div>
</template>

<script>
export default {
  name: "Clients",
  metaInfo: {
    title: "Clients",
  },
  components: {
    clientForm: () => import("../clients/ClientForm"),
    clientHome: () => import("../clients/ClientHome"),
  },
  data: (vm) => ({
      clientId: vm.$route.params.clientId,
      breadcrumbList: [{
        text: 'Clients',
        disabled: true,
        href: '/clients',
      }]
  }),
  watch: {
    $route(newVal) {
      this.clientId = newVal.params.clientId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.clientId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.clientId == 'create' ? 'Create' : 'Update'} Client`,
          disabled: true,
          href: `/clients/${this.clientId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Clients',
          disabled: true,
          href: '/clients',
        }]
      }
    }
  }
};
</script>
